.hero-banner {
    background: url('../../assets/images/hpbackground.svg') no-repeat center center/cover;
    height: 80vh; /* Adjusted from 100vh for better scrolling */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; 
  }
  
  .grey-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark grey overlay */
    z-index: 1; /* Ensure the overlay appears above the background but below the content */
  }
  
  .hero-content {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    margin-top: 100px;
    background-color: transparent;
    border-radius: 30px;
    z-index: 2;
    position: relative; /* Set position relative to position the button inside it */
  }
  
  .hero-content img {
    height: 100%;
    width: 100%;
    border-radius: 30px;
    position: relative; /* To make sure the button aligns correctly relative to the image */
  }
  
  .learn-more-button {
    position: absolute;
    bottom: 10%; /* Position the button 25% above the bottom of the image */
    left: 50%;
    transform: translateX(-50%); /* Center the button horizontally */
    background-color: var(--button-color);
    color: var(--button-text);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: var(--background-color) 0.3s ease;
    width: 40%;
  }
  
  .learn-more-button:hover {
    background-color: var(--complementary-accent);
  }