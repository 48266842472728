/* src/styles/theme.css */

:root {
    --primary-color: #1a1a1a;
    --secondary-color: #f0e68c;
    --background-color: #D3D3D3; 
    --background-color-two: white; 
    --text-color: #1a1a1a;
    --text-color-two: white;

    --button-color: #b80725; 
    --button-text: white; 

    --accent-color-1: #b80725;
    --neutral-accent: #D3D3D3;
    --complementary-accent: #e94454; 

    --brand-font: 'Permanent Marker', sans-serif;
    --second-font: 'Quicksand', serif;
    --backup-font: Arial, sans-serif;
}



/* General Styles */
body, html {
    height: 100vh;
    margin: 0;
    padding: 0;
    font-family: var(--second-font);
    color: var(--text-color);
    background-color: var(--background-color);
}

h1, h2 {
    font-family: var(--brand-font);
    color: var(--text-color);
    padding-top: 40px;
  }
  
  h1 {
    font-size: 2.5em;
    margin-bottom: 0.5em;
  }
  
  h2 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }
  
  p, li {
    line-height: 1.6;
    font-size: 1.2em;
    margin: 20px 0;
  }

  .text-image-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    flex-wrap: wrap; /* Ensure wrapping on smaller screens */
  }
  
  
  .section-image {
    width: 50%;
    height: auto;
    max-width: 600px;
    transition: transform 0.3s ease;
  }
  
  
  .section-image:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease;
  }
  
  .text-content {
    width: 55%;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .text-image-group {
      flex-direction: column;
      align-items: center;
    }
  
    
  .section-image {
    width: 100%;
    margin-bottom: 20px;
  }
  
    h1, h2, h3 {
      text-align: center;
    }
  }

  #homeWarning, #aboutWarning, #partnersCTA, #donateWarning {
    color: yellow;
    font-weight: bold;
    font-size: 1em;
  }