.about-container {
  font-family: var(--second-font);
  color: var(--text-color);
  background-color: var(--background-color);
  margin: 0 auto;
  text-align: center;
  padding: 20px;
}

/* Standard Section Styling */
.standard-section {
  margin: 60px 0;
  text-align: left;
}

/* Text-Image Group Styling */
.text-image-group {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 60px 40px;
}


.reverse {
  flex-direction: row-reverse;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .text-image-group {
    flex-direction: column;
    align-items: center;
  }
  .text-content {
    width: 100%;
    text-align: center;
  }

  .reverse {
    flex-direction: column;
  }
}

/* Button Styling (Matching Home) */
.cta-button {
  background-color: var(--button-color);
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: var(--complementary-accent);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .text-image-group {
    flex-direction: column;
    align-items: center;
  }

  .text-content {
    width: 100%;
    text-align: center;
  }

  .reverse {
    flex-direction: column;
  }
}