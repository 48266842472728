/* home.css */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  color: var(--background-color-two);
}

.home-hero-banner {
  background: url('../../assets/images/hpbackground.svg') no-repeat center center/cover;
  height: 80vh; /* Adjusted from 100vh for better scrolling */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-hero-content {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--text-color-two);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-top: 100px;
  background-color: rgba(256, 256, 256, 0.4);
  border: 5px solid var(--text-color-two);
  border-radius: 30px;
}

.home-hero-content img {
  max-height: 50vh;
  max-width: 70%;
  margin: 20px;
}

.home-logo-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 1em;
}

.home-learn-more-button {
  background-color: var(--button-color);
  color: var(--button-text);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: var(--background-color) 0.3s ease;
  margin-bottom: 50px;
  width: 80%;
}

.home-learn-more-button:hover {
  background-color: var(--complementary-accent);
}

/* Section Styles */
.full-width-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 60px 40px;
}

/* Overlay for sections with background images for legibility */
.join-community-section, .support-section {
  position: relative;
  color: var(--text-color-two);
  text-align: center;
}

.join-community-section::before, .support-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay for contrast */
  z-index: 1;
}

.join-community-section .community-text,
.support-section .support-text {
  position: relative;
  z-index: 2; /* Ensures text is above the overlay */
}


.offer-text, .features-text, .community-text, .support-text {
  width: 50%;
  padding: 20px;
}

.community-text h2, .support-text h2 {
  color: var(--text-color-two);
}

.offer-text p, .features-text ul, .upcoming-concerts-section {
  color: var(--text-color);
}

.reverse {
  flex-direction: row-reverse;
}

/* Button Styling */
.cta-button {
  background-color: var(--button-color);
  color: var(--button-text);
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: var(--background-color) 0.3s ease;
}

.cta-button:hover {
  background-color: var(--complementary-accent);
}

/* Parallax Section */
.parallax {
  background-attachment: fixed;
  background-size: cover;
  color: var(--text-color-two);
  text-align: center;
}

.upcoming-concerts-section {
  flex-direction: column;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .full-width-section {
    flex-direction: column;
    padding: 40px 20px;
  }


  .offer-text, .features-text, .community-text, .support-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .hero-content {
    max-width: 90%;
  }
}
