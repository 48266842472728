.hero-container {
    position: relative;
    background-size: cover;
    background-position: center;
    height: 400px; /* Adjust this based on your design needs */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  
  .hero-overlay {
    background-color: rgba(0, 0, 0, 0.5); /* Optional dark overlay */
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .hero-title {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    padding: 0 20px;
    color: var(--text-color-two);
  }
  
  @media (max-width: 768px) {
    .hero-title {
      font-size: 2rem; /* Make the title responsive for smaller screens */
    }
  }
  