/* General navbar styles */
.nav-item {
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.navbar {
    position: fixed;
    z-index: 1000;
    box-shadow: none;
    color: var(--text-color);
    font-weight: bold;
    background-color: white;
    margin: 5px;
}

.navbar-brand {
    font-family: var(--brand-font);
    font-size: calc(20px + (30 - 16) * ((100vw - 320px) / (1600 - 320)));
}

.navbar-logo {
    height: 60px;
}

.user-avatar {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    cursor: pointer;
}

/* For large screens: keep avatar aligned */
.navbar-icons {
    display: flex;
    align-items: center;
}
/* Dropdown menu for avatar */
.dropdown-menu {
    display: none; /* Initially hidden */
    position: absolute;
    top: 100%; /* Align to bottom of the avatar */
    right: 0;
    z-index: 1000;
    min-width: 15rem;
    padding: 0.5rem 0;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    list-style: none;
}

.dropdown-menu.show {
    display: block; /* Show when the 'show' class is added */
}

.navbar-icons .nav-link {
    display: flex;
    align-items: center;
}

.nav-link .cart-count {
    position: absolute;
    top: -5px;
    right: -10px;
    background-color: green;
    color: white;
    border-radius: 50%;
    padding: 0 6px;
    font-size: 12px;
}

/* Hamburger menu styling */
.navbar-toggler {
    display: none; /* Hidden by default */
    background: none;
    border: none;
    cursor: pointer;
    color: black;
}

/* Styles for middle section in desktop view */
.navbar-center {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    gap: 20px;
}

/* Burger menu on smaller screens */
@media (max-width: 768px) {
    .navbar-icons {
        display: none;
    }
    .navbar-center {
        display: none; /* Hide by default */
        flex-direction: column;
        align-items: center;
        background-color: white;
        position: absolute;
        top: 100%; /* Position right under the navbar */
        left: 0;
        width: 100%;
        border-top: 1px solid #eaeaea;
        padding: 20px 0;
    }

    .navbar-center.open {
        display: flex; /* Show when the menu is open */
    }

    .navbar-toggler {
        display: block; /* Show hamburger menu on small screens */
    }
}
