.support-page-container {
    font-family: var(--second-font);
    color: var(--text-color);
    background-color: var(--background-color);
    margin: 0 auto;
    text-align: center;
    padding: 20px;
  }
  
  /* Section Styles */
  .full-width-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 60px 40px;
  }
  
  /* Overlay for sections with background images for legibility */
  .support-page-section.has-background {
    position: relative;
    color: var(--text-color-two);
    text-align: center;
  }

  .section-image{
    z-index: 2;
  }
  
  .support-page-section.has-background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay for contrast */
    z-index: 1;
  }
  
  .support-page-section.has-background .community-text,
  .support-page-section.has-background .support-text {
    position: relative;
    z-index: 2; /* Ensures text is above the overlay */
  }
  
  .offer-text, .features-text, .community-text, .support-text {
    width: 50%;
    padding: 20px;
  }
  
  .community-text h2, .support-text h2 {
    color: var(--text-color-two);
  }
  
  .offer-text p, .features-text ul, .upcoming-concerts-section {
    color: var(--text-color);
  }
  
  .reverse {
    flex-direction: row-reverse;
  }
  
  /* Button Styling */
  .cta-button {
    background-color: var(--button-color);
    color: var(--button-text);
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: var(--background-color) 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: var(--complementary-accent);
  }
  
  /* Parallax Section */
  .parallax {
    background-attachment: fixed;
    background-size: cover;
    color: var(--text-color-two);
    text-align: center;
  }
  
  .upcoming-concerts-section {
    flex-direction: column;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .full-width-section {
      flex-direction: column;
      padding: 40px 20px;
    }
  
    .offer-text, .features-text, .community-text, .support-text {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .hero-content {
      max-width: 90%;
    }
  }
  