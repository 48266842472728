.slider {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory; /* Ensure snapping behavior */
  scroll-behavior: smooth; /* Smooth scrolling */
}

.slider-container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.slider-content {
  flex: 0 0 auto;
  scroll-snap-align: start; /* Snap to the start of each item */
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  width: 100%; /* Each item takes full width on mobile */
}

  
  .slider-image {
    max-width: 150px; /* Reduce image size to fit better */
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .slider-text {
    max-width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  
  .slider-btn {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }
  
  .prev-btn {
    left: 10px;
  }
  
  .next-btn {
    right: 10px;
  }
  
  @media (min-width: 1024px) {
    .slider-content {
      width: 33.33%; /* Each slide takes up 33.33% of the container on desktop */
    }
  }
  
  @media (max-width: 1024px) and (min-width: 768px) {
    .slider-content {
      width: 50%; /* Each slide takes up 50% of the container on tablet */
    }
  }
  
  @media (max-width: 768px) {
    .slider-content {
      width: 450px;
      font-size: .8rem
    }
    .slider-btn {
      font-size: 1.5rem;
    }
  }
  