.concerts-container {
  font-family: var(--second-font);
  color: var(--text-color);
  background-color: var(--background-color);
  margin: 0 auto;
    padding: 20px;
    text-align: center;
    min-height: 100vh;
  }
  
  .chip-container {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .chip {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: #f0f0f0;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .chip.active {
    background-color: #ff4b5c;
    color: white;
  }
  
  .chip:hover {
    background-color: #ff4b5c;
    color: white;
  }
  
  .concerts-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .concert-card {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    padding: 20px;
    text-align: left;
  }
  
  .concert-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  
  @media (max-width: 768px) {
    .concerts-list {
      flex-direction: column;
      align-items: center;
    }
  
    .concert-card {
      width: 100%;
      max-width: 400px;
    }
  }
  