/* Footer container */
.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px;
    background-color: var(--primary-color);
    color: var(--background-color-two);
    flex-wrap: wrap; /* Ensures wrapping on smaller screens */
    margin-top: 200px;
  }

  
  footer h2 {
    color: var(--text-color-two);
  }
  
  
  /* Footer sections */
  .footer-section {
    flex: 1;
    margin: 20px;
  }
  
  /* Contact Form Styles */
  .contact-form form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form input, .contact-form textarea {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid var(--neutral-accent);
    border-radius: 5px;
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  .contact-form button {
    background-color: var(--button-color);
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: var(--complementary-accent);
  }

  .social-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  /* Social Links and Footer Links */
  .social-links ul, .footer-links ul {
    list-style-type: none;
    padding: 0;
  }
  
  .social-links ul li, .footer-links ul li {
    margin-bottom: 10px;
  }
  
  .social-links ul li a, .footer-links ul li a {
    color: var(--background-color-two);
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .social-links ul li a:hover, .footer-links ul li a:hover {
    color: var(--complementary-accent);
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-section {
      margin: 20px 0;
    }
  }
  