.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('../../assets/images/hpbackground.svg') no-repeat center center/cover;
}

.auth-form {
  background: transparent;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.auth-form h1 {
  margin-bottom: 1rem;
  font-size: 24px;
  color: white;
}

.auth-form .error {
  color: red;
  margin-bottom: 1rem;
}

.auth-form form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.auth-form input {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.auth-form button {
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.auth-form button:hover {
  background-color: #0056b3;
}

.auth-form p {
  margin-top: 1rem;
  font-size: 14px;
  color: #555;
}

.auth-form a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s;
}

.auth-form a:hover {
  color: #0056b3;
}
